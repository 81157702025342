import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import Service from "../components/service";
import ServiceList from "../components/serviceList";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";
import DidYouKnow from "../components/didYouKnow";

class BusinessInsurance extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Baton Rouge Business Insurance | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "As a Baton Rouge based business, choosing a locally-owned, independent insurance agency, like Lewis Mohr Insurance Agency, will give you piece of mind knowing you have a trusted partner in your corner.  Contact us for a quote!",
            },
          ]}
        />
        <MainBg
          mainBg={this.props.data.mainBg}
          imgStyle={{ objectPosition: "top" }}
          alt="Bussiness conference room meeting"
        />
        <Callout
          title="Baton Rouge Business Insurance"
          content="As a Baton Rouge based business, choosing Lewis Mohr Insurance Agency, a locally-owned, independent insurance agency, will give you piece of mind knowing you have a trusted partner. Business insurance is not a commodity, but a specialized product, which is why it's important to have experts like us in your corner. Below you'll find our most popular offerings that you can learn more about."
        >
          <div className="servicesVideoWrapper">
            <div className="videoWrapper">
              <iframe
                className="iframe services-video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/-dPBh0Vab3I?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Baton Rouge Business Insurance with Lewis Mohr Insurance Agency"
              />
            </div>
          </div>
        </Callout>
        <div className="site-body page-content">
          <h2>For Your Operations</h2>
          <div className="services-title-seperator"></div>
          <ServiceList>
            <Service
              serviceName="Business Interruption Insurance"
              serviceURL={"/business-interruption-insurance/"}
              serviceDescription="Covers the loss of income after a disaster. The income loss can be from closing because of the disaster or due to rebuilding."
            />

            <Service
              serviceName="Cyber Liability Insurance"
              serviceURL={"/cyber-liability-insurance/"}
              serviceDescription="Covers the financial losses and expenses that result from data breaches and other cyber attacks, such as ransomware."
            />
            <Service
              serviceName="General Liability Insurance"
              serviceURL={"/general-liability-insurance/"}
              serviceDescription="Helps cover claims from your normal business operations, such as property damage, bodily injuries, defense costs, and more."
            />
            <Service
              serviceName="Professional Liability Insurance"
              serviceURL={"/professional-liability-insurance/"}
              serviceDescription="Covers your business from claims of negligence related to a professional service such as Attorneys, Doctors, Engineers, and Architects."
            />
            <Service
              serviceName="Small Business Owner's Policy"
              serviceURL={"/small-business-owners-policy-bop/"}
              serviceTitle="Small Business Owner's Policy"
              serviceDescription="Bundles General Liability and Commercial Property Insurance into a more affordable insurance package."
            />
            <Service
              serviceName="Umbrella Excess Insurance"
              serviceURL={"/umbrella-excess-insurance/"}
              serviceDescription="Designed to complement your base policy by offering additional coverage for the most expensive claims."
            />
          </ServiceList>
          <DidYouKnow
            fact="Lewis Mohr Insurance Agency has been serving Baton Rouge and all of Louisiana since 1968, and has been awarded the BBB Torch for Ethics."
            source=""
          />
          <h2>For Your Property</h2>
          <div className="services-title-seperator"></div>
          <ServiceList>
            <Service
              serviceName="Commercial Auto Insurance"
              serviceURL={"/commercial-auto-insurance/"}
              serviceDescription="This coverage is needed if you use your vehicles for business. It covers liability and physical damage protection for your business vehicles and trailers."
            />
            <Service
              serviceName="Commercial Drone Insurance"
              serviceURL={"/commercial-drone-insurance/"}
              serviceDescription="Covers property damage, bodily injury, hull damage, and payload from the operation of a drone."
            />
            <Service
              serviceName="Commercial Flood Insurance"
              serviceURL={"/commercial-flood-insurance/"}
              serviceDescription="Covers physical damage to buildings from or by flooding. Includes damage to floors, walls, ceilings, electrical systems, and more."
            />
            <Service
              serviceName="Commercial Property Insurance"
              serviceURL={"/commercial-property-insurance/"}
              serviceDescription="Covers your company's physical assets from fire, burst pipes, storms, theft and vandalism."
            />
            <Service
              serviceName="Equipment Breakdown Insurance"
              serviceURL={"/equipment-breakdown-insurance/"}
              serviceDescription="Covers damages from covered internal forces, such as power surges, electrical shorts, mechanical breakdowns, motor burnout or operator error."
            />

            <Service
              serviceName="Inland Marine Insurance"
              serviceURL={"/inland-marine-insurance/"}
              serviceDescription="Is property insurance for property that is movable or is a specialized type of property."
            />
          </ServiceList>

          <h2>For Your Employees</h2>
          <div className="services-title-seperator"></div>
          <ServiceList>
            <Service
              serviceName="Directors & Officers"
              serviceURL={"/directors-officers-liability-insurance/"}
              serviceDescription="Covers directors and officers or their company or organization if sued."
            />
            <Service
              serviceName="Group Health Insurance"
              serviceURL={"/group-health-insurance/"}
              serviceDescription="Provides health insurance coverage to a company's employees, often at a more afforable price than an individual policy."
            />
            <Service
              serviceName="Group Life Insurance"
              serviceURL={"/group-life-insurance/"}
              serviceDescription="Provides life insurance coverage to a company's employees, often at a more afforable price than an individual policy."
            />

            <Service
              serviceName="Workers' Compensation"
              serviceURL={"/workers-compensation/"}
              serviceDescription="Provides wage replacement and medical benefits to employees injured in the course of employment."
            />
          </ServiceList>
        </div>
        <CallToAction
          bg={this.props.data.callToActionBg}
          type="a Business Insurance"
        />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default BusinessInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "home-new.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-7.jpg" }) {
      ...mainBg
    }
  }
`;
